<template>
  <div>
    <ais-instant-search
      :search-client="searchClient"
      index-name="russell"
      :stalled-search-delay="1000"
    >
      <Header :productos_carrito="cantidad_carrito"></Header>
      <div class="clearfix"></div>
      <div class="container py-5">
        <div class="search-panel__results">
          <ais-sort-by
            :items="[
              { value: 'russell', label: 'Todos los productos' },
              { value: 'russell:precio_lista:asc', label: 'Menor precio' },
              { value: 'russell:precio_lista:desc', label: 'Mayor precio' },
              { value: 'russell:nombre:asc', label: 'Nombre A-Z' },
              { value: 'russell:nombre:desc', label: 'Nombre Z-A' },
            ]"
            class="d-flex justify-content-end text-center"
          />

          <ais-hits class="mt-4">
            <template slot="item" slot-scope="{ item }">
              <div>
                <div class="hit-name">
                  <strong
                    ><ais-highlight :hit="item" attribute="nombre"
                  /></strong>
                </div>
                <img
                  v-if="item.image != 'https://via.placeholder.com/300'"
                  class="hit-image"
                  :src="item.image"
                  :alt="item.image"
                  :key="item.image"
                  @click="showModal(item)"
                />
                <img
                  v-if="item.image == 'https://via.placeholder.com/300'"
                  class="hit-image"
                  src="@/assets/logo-azul.png"
                  :alt="item.image"
                  :key="item.image"
                />
                <small class="leyenda">Fotografía ilustrativa</small>
                <div class="hit-description">
                  <ais-snippet
                    v-if="item.descripcion != null"
                    :hit="item"
                    attribute="descripcion"
                  />
                  <ais-snippet
                    v-if="item.descripcion == null"
                    :hit="item"
                    attribute=""
                  />
                </div>
                <div class="hit-info">
                  <strong>SKU:</strong>
                  <ais-highlight :hit="item" attribute="codigo_anterior" />
                </div>
                <div class="hit-info">
                  <strong>PRECIO:</strong> ${{ item.precio_lista }}
                  <br />
                  <strong>UNIDAD:</strong> {{ item.unidad_medida }}
                  <br />
                  <small class="leyenda"
                    >Todos nuestros precios incluyen I.V.A.</small
                  >
                  <br />
                  <small class="leyenda">Precio de contado</small>
                  <br /><br />
                  <div class="row d-flex justify-content-center">
                    <div class="col-md-6 text-center">
                      <label class="form-label"><b>Cantidad</b></label>
                      <input
                        class="form-control text-center"
                        type="number"
                        name=""
                        id=""
                        placeholder="1"
                        v-model="item.cantidad_agregar"
                        min="1"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 d-grid gap-2">
                      <small class="badge rounded-pill bg-danger" v-if="item.cantidad_agregar <= 0">La cantidad debe ser mayor a 0</small>
                      <button
                        class="btn btn-primary add-cart mt-3"
                        @click="addCart(item)" 
                        :disabled="item.cantidad_agregar <= 0"
                      >
                        Agregar al carrito
                      </button>
                    </div>
                  </div>
                  <!--<div class="d-grid">
                     <button class="btn btn-primary d-flex justify-content-center align-items-end add-cart mt-3" @click="addCart(item.id)">
                      Agregar al carrito
                    </button>
                  </div>-->
                </div>
              </div>
            </template>
          </ais-hits>

          <ais-configure
            :attributesToSnippet="['description:50']"
            snippetEllipsisText="…"
          />
          <ais-pagination />
        </div>
      </div>
    </ais-instant-search>
  </div>
</template>

<script>
import "instantsearch.css/themes/algolia-min.css";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import Header from "@/components/Header.vue";
import { URL_BUSCADOR, API_KEY_BUSCADOR } from "@/config/config.js";
import Swal from "sweetalert2";

export default {
  components: {
    Header,
  },
  data() {
    return {
      searchClient: instantMeiliSearch(URL_BUSCADOR, API_KEY_BUSCADOR, {
        paginationTotalHits: 20000,
      }),
      carrito: [],
      num_verf: 0
    };
  },
  mounted() {
    this.init()

  },
  methods: {
    init() {
      this.getCarrito()
    },
    getCarrito() {

      const carritoJson = localStorage.getItem('carrito');
      this.carrito = (carritoJson != null) ? JSON.parse(carritoJson) : [];

    },
    showModal(producto) {
      const fotos =
        producto.fotos_url.length > 0 ? producto.fotos_url : [producto.image];
      this.$viewerApi({
        images: fotos,
      });
    },
    addCart(producto) {
      if (!producto.cantidad_agregar) {
        producto.cantidad_agregar = 1;
      }
      console.log(producto.cantidad_agregar)
      if (Number(producto.cantidad_agregar) % 1 == 0) {
         this.num_verf = 'entero';
      } else {
        this.num_verf = 'decimal';
      }

      if (producto.forma_venta == 'entero' && this.num_verf == 'decimal') {
        Swal.fire("¡Atención!", 'La forma de venta de este producto es Entero', 'warning');
      }else if(producto.forma_venta == 'multiplos'){
        let multiplo = Number(producto.cantidad_agregar) / Number(producto.multiplos);
        if (multiplo % 1 == 0) {
          //  1. revisar si el producto ya existe en array de carrito (find)
          // 2. si existe se le suma 1, si no existe se mete producto en array carrito (push)
          const existe = this.carrito.find((e) => e.id == producto.id);
          //console.log('EXISTE:' +existe.cantidad);
          if (existe) {
            //console.log('CANTIDAD AGREGAR: ' + producto.cantidad_agregar)
            if (producto.cantidad_agregar) {
              //console.log(existe.cantidad)
              //console.log(producto.cantidad_agregar)
              existe.cantidad = Number(existe.cantidad) + Number(producto.cantidad_agregar);
            }else{
              existe.cantidad++;
            }
            
          } else {
            //console.log('CANTIDAD AGREGAR: ' + producto.cantidad_agregar)
            if (producto.cantidad_agregar) {
              const producto_cotizador = {
                cantidad: producto.cantidad_agregar,
                ...producto,
              };
              this.carrito.push(producto_cotizador);
            }else{
              const producto_cotizador = {
                cantidad: 1,
                ...producto,
              };
              this.carrito.push(producto_cotizador);
            }
            
          }
          
          //console.log("carrito:", this.carrito);
          Swal.fire({
            title: "Éxito!",
            text: `${producto.nombre} ha sido añadido al carrito`,
            icon: "success",
            showCancelButton: true,
            cancelButtonText: 'Continuar cotizando',
            confirmButtonText: "Finalizar cotización",
          }).then(result => {
            if(result.isConfirmed){
              this.$router.push({name: 'carrito'});
            }
          });
          
        } else {
          Swal.fire("¡Atención!", 'Solo múltiplos de ' + producto.multiplos, 'warning');
        }
      }else{
        //  1. revisar si el producto ya existe en array de carrito (find)
          // 2. si existe se le suma 1, si no existe se mete producto en array carrito (push)
          const existe = this.carrito.find((e) => e.id == producto.id);
          //console.log('EXISTE:' +existe.cantidad);
          if (existe) {
            //console.log('CANTIDAD AGREGAR: ' + producto.cantidad_agregar)
            if (producto.cantidad_agregar) {
              //console.log(existe.cantidad)
              //console.log(producto.cantidad_agregar)
              existe.cantidad = Number(existe.cantidad) + Number(producto.cantidad_agregar);
            }else{
              existe.cantidad++;
            }
            
          } else {
            //console.log('CANTIDAD AGREGAR: ' + producto.cantidad_agregar)
            if (producto.cantidad_agregar) {
              const producto_cotizador = {
                cantidad: producto.cantidad_agregar,
                ...producto,
              };
              this.carrito.push(producto_cotizador);
            }else{
              const producto_cotizador = {
                cantidad: 1,
                ...producto,
              };
              this.carrito.push(producto_cotizador);
            }
            
          }
          
          //console.log("carrito:", this.carrito);
          Swal.fire({
            title: "Éxito!",
            text: `${producto.nombre} ha sido añadido al carrito`,
            icon: "success",
            showCancelButton: true,
            cancelButtonText: 'Continuar cotizando',
            confirmButtonText: "Finalizar cotización",
          }).then(result => {
            if(result.isConfirmed){
              this.$router.push({name: 'carrito'});
            }
          });

      }
     },
  },
  computed: {
    cantidad_carrito() {
     let suma = 0;

     suma = this.carrito.reduce(function (suma, curVal) {
       //console.log(suma, curVal.cantidad)
        return Number(suma) + Number(curVal.cantidad)
      }, 0);
     
     /* this.carrito.forEach(e => {
        suma += e.cantidad
      });*/
      //console.log(suma)
      return Number(suma);

    }
  },
  watch: {
    carrito: {      
      deep: true,
      handler(nuevo){
        const json = JSON.stringify(nuevo);
        localStorage.setItem('carrito', json);
      }

    }
  }
};
</script>

<style>
.ais-Hits-item img {
  margin-right: 1em;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.ais-Highlight-highlighted {
  background: cyan;
  font-style: normal;
}

.ais-SearchBox-input.form-control {
  padding-left: 2em;
}

.disclaimer {
  margin-left: 1em;
}

.hit-name {
  margin-bottom: 0.5em;
  min-height: 3em;
}

.hit-info {
  font-size: 90%;
}

.hit-description {
  font-size: 90%;
  margin-bottom: 0.5em;
  color: grey;
}

.ais-InstantSearch {
  overflow: hidden;
  margin: 0;
}

.ais-Pagination {
  margin: 2rem auto;
  text-align: center;
}
.leyenda {
  font-size: 11px;
}
.container {
  margin-top: 120px;
}
.add-cart {
  background-color: #004067;
  color: white;
}
.add-cart:hover {
  background-color: #036aaa;
}
.swal2-styled.swal2-confirm {
  background-color: #004067;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: #004067;
}
img.hit-image {
  height: 250px;
}

@media (max-width: 821px) {
  .container {
    margin-top: 100px;
  }
  .ais-Hits-item,
  .ais-InfiniteHits-item,
  .ais-InfiniteResults-item,
  .ais-Results-item {
    width: calc(50% - 1em);
    margin-bottom: 1em;
  }
}
@media (max-width: 576px) {
  .ais-Hits-item,
  .ais-InfiniteHits-item,
  .ais-InfiniteResults-item,
  .ais-Results-item {
    width: 100%;
    margin-bottom: 1em;
  }
  .ais-Hits {
    padding: 0 1em 0 1em;
  }
  .container {
    margin-top: 170px;
  }
}
</style>
