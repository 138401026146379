<template>
  <div id="app">
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer

  },
  
  data() {
    return {
      
    };
  },
  methods: {
    
  }
};
</script>

<style>
.text-skyblue {
  color: #48adeb;
}

</style>
