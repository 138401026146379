<template>
  <div>
    <HeaderContacto></HeaderContacto>
    <div class="clearfix"></div>
    <div class="container">
      <div
        class="contenedor-carrito align-items-center justify-items-center py-5"
      >
        <h1>Carrito</h1>
        <div class="mb-3" v-if="url_cotizacion != ''">
            <div class="alert alert-success">
                <span>Tu cotización se ha generado con éxito</span>
            </div>
            <a class="btn btn-success me-3" target="_blank" :href="url_cotizacion">Da click para verla</a>
            <button class="btn btn-primary" @click="regresar">Regresar al inicio</button>
        </div>
        <template v-if="carrito.length">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Producto</th>
                <th scope="col">Precio Unitario</th>
                <th scope="col">Precio Unitario + IVA</th>
                <th scope="col" class="text-center">Cantidad</th>
                <th scope="col">Subtotal</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="producto in carrito" :key="producto.codigo_anterior">
                <th scope="row">
                  <img width="50" :src="producto.fotos_url[0]" alt="" @click="showModal(producto)" />
                </th>
                <td>
                  <p>{{ producto.nombre }}</p>
                </td>
                <td>
                  <p>{{ formatPrice(producto.precio_unitario) }}</p>
                </td>
                <td>
                  <p>{{ formatPrice(producto.precio_lista) }}</p>
                </td>
                <td class="text-center">
                  <input
                    class="form-control text-center"
                    type="number"
                    name=""
                    id=""
                    v-model="producto.cantidad"
                    min="1"
                    @change="actualizar_cantidad_producto(producto)"
                  />
                  <small class="badge rounded-pill bg-danger" v-if="producto.cantidad <= 0">La cantidad debe ser mayor a 0</small>
                </td>
                <td>
                  <p v-if="producto.cantidad * producto.precio_unitario <= 0">$0.00</p>
                 <!-- <p v-else>{{ Math.round((producto.cantidad * producto.precio_unitario) * 100) / 100 }}</p>-->
                 <p v-else>{{formatPrice(producto.cantidad * producto.precio_unitario)}}</p>
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-outline-danger"
                    @click="eliminar(producto)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            
            <h5>Resumen</h5>    
            <div class="row">
              <div class="col-sm-2">
                SUBTOTAL:
              </div>
              <div class="col-sm-2">
                <b>{{formatPrice(subtotal)}}</b>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                I.V.A.:
              </div>
              <div class="col-sm-2">
                <b>{{formatPrice(iva)}}</b>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                TOTAL:
              </div>
              <div class="col-sm-2">
                <b>{{formatPrice(total)}}</b>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button :disabled="enviando || !valido" @click="generar_cotizacion" class="btn btn-success">
              {{enviando ? 'Generando Cotización...' : 'Generar Cotización'}}
            </button>
          </div>
        </template>
        <template v-else-if="url_cotizacion == ''">
            <div class="alert alert-warning">
                <span>No hay productos en el carrito</span>
            </div>
            <button class="btn btn-primary" @click="regresar">Regresar al inicio</button>
        </template>
        
      </div>
    </div>
  </div>
</template>
<script>
import HeaderContacto from "@/components/HeaderContacto.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { URL_COTIZACION } from "@/config/config.js";

const moneyFormatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  maximumFractionDigits: 2
});


export default {
  components: {
    HeaderContacto,
  },
  data() {
    return {
      enviando: false,
      carrito: [],
      url_cotizacion: '',
      subtotal: 0,
      iva: 0,
      total: 0,
      num_verf: 0
    };
  },
  computed: {
    valido() {
      console.log(this.carrito)
      for (const producto of this.carrito) {
        // if(producto.cantidad < 1){
        //   return false;
        // }
        if(producto.cantidad == 0){
          return false;
        }
      }
      

      return true;
    }

  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCarrito();
      window.scrollTo(0, 0);
    },
    getCarrito() {
      const carritoJson = localStorage.getItem("carrito");
      this.carrito = carritoJson != null ? JSON.parse(carritoJson) : [];
    },
    generar_cotizacion() {
      console.log(this.carrito);
      this.enviando = true;
      axios
        .post(URL_COTIZACION, { productos: this.carrito })
        .then((req) => {
          var res = req.data;
          if (res.error) {
            Swal.fire({
              title: "Error!",
              text: res.mensaje,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
            this.enviando = false;
          } else {
            Swal.fire({
              title: "Éxito!",
              text: res.mensaje,
              icon: "success",
              confirmButtonText: "Aceptar",
            }).then(() => {
              this.carrito = [];
              this.enviando = false;
              this.url_cotizacion = res.open_url;
              window.open(res.open_url);
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: "Error!",
            text: "Ocurrió un error al conectarse",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          this.enviando = false;
        });
    },
    eliminar(producto) {
      const index = this.carrito.findIndex(
        (_producto) => producto.id == _producto.id
      );
      if (index != -1) {
        Swal.fire({
          title: "¿Seguro que quieres eliminar este producto?",
          showDenyButton: true,
          //showCancelButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$delete(this.carrito, index);
            Swal.fire("Producto Eliminado", "", "success");
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
        });
        //this.$delete(this.carrito, index);
      }
    },
    regresar() {
      this.$router.push({name: 'home'});
      this.url_cotizacion = '';
    },
    formatPrice(value) {

      return moneyFormatter.format(value);
        // let val = (value/1).toFixed(2).replace(',', '.')
        // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    showModal(producto) {
      const fotos =
        producto.fotos_url.length > 0 ? producto.fotos_url : [producto.image];
      this.$viewerApi({
        images: fotos,
      });
    },
    actualizar_cantidad_producto: function (producto) {
			if (producto.cantidad % 1 == 0) {
				this.num_verf = 'entero';
			} else {
				this.num_verf = 'decimal';
			}
			if (producto.forma_venta == 'entero' && this.num_verf == 'decimal') {
				Swal.fire("¡Atención!", 'La forma de venta de este producto es Entero', 'warning');
				producto.cantidad = 5;
			}else if(producto.forma_venta == 'multiplos'){
				var multiplo = producto.cantidad / producto.multiplos;
				if (multiplo % 1 == 0) {

          producto.cantidad
					
					
				} else {
					Swal.fire("¡Atención!", 'Solo múltiplos de ' + producto.multiplos, 'warning');
					producto.cantidad = producto.multiplos;
				}
			} else{ 
				producto.cantidad

			}
		},
  },
  watch: {
    carrito: {
      deep: true,
      handler(nuevo) {
        const json = JSON.stringify(nuevo);
        localStorage.setItem("carrito", json);

        const suma = this.carrito.reduce(function (suma, curVal) {
        //console.log(suma, curVal.cantidad)
        return Number(suma) + Number(curVal.precio_unitario * curVal.cantidad)
        }, 0);
        if (suma < 0) { 
          this.subtotal = 0;        
          this.iva = 0;
          this.total = 0;
        }else{
          this.subtotal = suma;
          this.iva = this.subtotal * 0.16;
          this.total = this.subtotal + this.iva;
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
form {
  margin-top: 70px;
}
.contacto2 {
  padding-top: 150px;
  width: 100%;
}
.datos-contacto {
  width: 100%;
}
.seccion-izquierda {
  position: absolute;
  width: 100%;
}
.btn-enviar {
  background-color: #004067;
  border-color: #004067;
  text-align: center;
}
.direccion.align-items-center {
  justify-content: center;
  text-align: center;
}
.telefono.mt-2 {
  text-align: center;
}
form.p-2.flex-fill.mx-5 {
  justify-content: center;
  text-align: center;
}
label {
  font-size: 25px;
}
.text-blue {
  color: #004067;
}

.contenedor-carrito {
  min-height: 75vh;
}

@media (max-width: 576px) {
  .contenedor-carrito {
    flex-direction: column;
  }
}
</style>