export const URL_BUSCADOR = 'https://buscador.server.plasticosrussell.com';
export const URL_API = "https://api.server.plasticosrussell.com/api/sitio-web";
export const URL_CONTACTO = URL_API+"/contacto";
export const URL_COTIZACION = URL_API+"/cotizacion";
export const API_KEY_BUSCADOR =  "K0wn5EUZ206a662b048a20ddccd19fbcfa89b8405b8b960b0a7b1a6187049b925e0e76fd";

//export const URL_API = "http://localhost/russell-web/api/sitio-web";
/*export const URL_BUSCADOR = 'http://gps.aregagps.com:7700';
export const URL_CONTACTO = "http://gps.aregagps.com:7700/api/sitio-web/contacto";
export const API_KEY_BUSCADOR =  "Vmwcz97m1b3edf524a701d99f504edfce9ccbeacb1f874a82ceb1523b33c232a96b43c98";*/
