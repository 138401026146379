<template>
    <footer class="footer d-flex justify-content-center">
        <p class="">© 2022 | Hecho con <i class="fas fa-mug-hot text-skyblue"></i> y <i class="fas fa-heart text-danger"></i> en Teko Estudio</p>
    </footer>
    
</template>
<script>
export default {
    
}
</script>
<style scoped lang="scss">
.footer {
    background-color: #004067;
    display: block;
    padding: 1em;
    color: white;
    p {
        margin: 0;
    }
}


</style>