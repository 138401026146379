import Vue from 'vue';
import App from './App.vue';
import InstantSearch from "vue-instantsearch";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import VueRouter from 'vue-router';
import Home from './components/Home.vue';
import Contacto from './components/Contacto.vue';
import Carrito from './components/Carrito.vue';

Vue.use(InstantSearch);
Vue.use(VueViewer);
Vue.use(VueRouter);
const router = new VueRouter({
  routes: [  
    {
      path: '/',
      component: Home,
      name: 'home'
    },
    {
      path: '/contacto',
      component: Contacto
    },
    {
      path: '/carrito',
      component: Carrito,
      name: 'carrito'
    }
    
  ],
  mode: 'history'

});
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
