<template>
    <header class="header d-flex justify-content-between align-items-center px-5 py-3">
        <img id="logo" src="@/assets/logo.svg"/>
        <ais-search-box class="buscador" placeholder="Buscar ahora" :class-names="{
            'ais-SearchBox-input': 'form-control'
        }" show-loading-indicator />
        <div class="seccion-derecha">
            <div class="direccion d-flex align-items-center">
                <i class="fas fa-map-marker-alt me-3 text-skyblue"></i>
                <p>
                    <strong>Avenida de la Convención de 1914 Norte #2007</strong> <br>
                    Fraccionamiento Buenos Aires, AGS,MX
                </p>
            </div>
            <div class="telefono d-flex align-items-center mt-2">
                <i class="fas fa-phone me-3 text-skyblue"></i>
                <p>+52 (449) 914 99 91</p>  
            </div>
            <div class="contacto d-flex align-items-center mt-2">
                <i class="fas fa-envelope me-3 text-skyblue"></i>
                <router-link class="menu" to="/contacto">CONTÁCTANOS</router-link>
            </div>
            
        </div>
        <button @click="$router.push({name: 'carrito'})" type="button" class="btn btn-link position-relative">
            <i class="fas fa-shopping-cart text-skyblue"></i>
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {{productos_carrito}}
            </span>
        </button>
        
         <!--<router-link class="menu" to="/contacto">CONTACTO</router-link>-->
    </header>
</template>
<script>
export default {
    props: {
        productos_carrito: {
            type: Number
        }
    }
    

    
}
</script>
<style scoped lang="scss">

.header {
  background-color: #004067;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.direccion {
    i {
        font-size: 1.6em;
    }
}
.telefono {
    i {
        font-size: 1.2em;
    }
}
.contacto {
    i {
        font-size: 1.2em;
    }
}
p {
    margin: 0;
}
#logo {
    max-height: 80px;
}
.buscador { 
    width: 40%;
}
.menu{
    color: white;
    
}
a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
sup{
    font-size: 20px;
    color: white;
}
.fa-shopping-cart {
    font-size: 20px;

}
@media (max-width: 821px) { 
  .direccion {
        display: none !important;
    }
    .telefono{
        display: none !important;
    }
}
@media (max-width: 576px){
    .buscador{
        width: 100%;
        margin: 1em 0 1em 0;
    }
    .header{
        flex-direction: column;
    }
    

}



</style>