<template>
  <div>
    <HeaderContacto></HeaderContacto>
    <div class="contacto2 d-flex justify-content-center align-items-center">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.98949066251!2d-102.30949838573898!3d21.89648046296642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eef4452e86d3%3A0x11fe791b614dcff9!2sRUSSELL!5e0!3m2!1ses!2smx!4v1642618660360!5m2!1ses!2smx"
        width="800"
        height="600"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
    <div class="contenedor-contacto d-flex align-items-center py-5">
      <div class="p-2 flex-fill">
        <div class="direccion align-items-center">
          <i class="fas fa-map-marker-alt me-3 text-blue"></i>
          <p>
            <strong>Avenida de la Convención de 1914 Norte #2007</strong> <br />
            Fraccionamiento Buenos Aires, AGS,MX
          </p>
        </div>
        <div class="telefono mt-2">
          <i class="fas fa-phone me-3 text-blue"></i>
          <p>+52 (449) 914 99 91</p>
        </div>
      </div>
      <form class="p-2 flex-fill mx-5" @submit.prevent="enviar()">
        <label>Déjanos tu comentario</label>
        <input
          type="text"
          class="form-control my-2"
          placeholder="Nombre"
          v-model="formulario.nombre"
          required
        />
        <input
          type="text"
          class="form-control my-2"
          placeholder="Email"
          v-model="formulario.email"
          required
        />
        <input
          type="number"
          class="form-control my-2"
          placeholder="Teléfono"
          v-model="formulario.telefono"
          required
        />
        <textarea
          class="form-control my-2"
          placeholder="Mensaje"
          v-model="formulario.mensaje"
          required
        ></textarea>
        <div class="d-grid gap-2 col-6 mx-auto">
          <button type="submit" :disabled="enviando" class="btn btn-primary btn-enviar">
            {{enviando ? 'Enviando...' : 'Enviar'}}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import HeaderContacto from "@/components/HeaderContacto.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { URL_CONTACTO } from '@/config/config.js';


export default {
  components: {
    HeaderContacto,
  },
  data() {
    return {
      formulario: {
        nombre: "",
        email: "",
        telefono: "",
        mensaje: "",
      },
      enviando: false
    };
  },
  methods: {
    enviar: function () {
        this.enviando = true;
      /* axios.post(URL_CONTACTO, this.formulario).then(function(res) {
              
          }.bind(this))*/
      axios
        .post(URL_CONTACTO, this.formulario)
        .then((req) => {
          var res = req.data;
          if (res.error) {
            Swal.fire({
              title: "Error!",
              text: res.mensaje,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
            this.enviando = false;
          } else {
            Swal.fire({
              title: "Éxito!",
              text: res.mensaje,
              icon: "success",
              confirmButtonText: "Aceptar",
            }).then(() => {
              this.formulario = {
                nombre: "",
                email: "",
                telefono: "",
                mensaje: "",
              };
              this.enviando = false;
            });
          }
        })
        .catch(() => {
          Swal.fire({
              title: "Error!",
              text: 'Ocurrió un error al conectarse',
              icon: "error",
              confirmButtonText: "Aceptar",
            });
            this.enviando = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.contacto2 {
  padding-top: 150px;
  width: 100%;
}
.datos-contacto {
  width: 100%;
}
.seccion-izquierda {
  position: absolute;
  width: 100%;
}
.btn-enviar {
  background-color: #004067;
  border-color: #004067;
  text-align: center;
}
.direccion.align-items-center {
  justify-content: center;
  text-align: center;
}
.telefono.mt-2 {
  text-align: center;
}
form.p-2.flex-fill.mx-5 {
  justify-content: center;
  text-align: center;
}
label {
  font-size: 25px;
}
.text-blue {
   color: #004067;
}
@media (max-width: 576px){
    .contenedor-contacto{
        flex-direction: column;
    }
    

}
</style>